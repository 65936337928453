import axios from 'axios';
import authHeader from './auth-header';
import API_URL from '@/services/url.js';

function handleAPIError(error) {
    console.error("Error!")
    console.error({error})
    if (error.response.status === 401) {
        console.log("You need to log in again!")
        localStorage.removeItem('user');
        location.reload();
        // Redirect to login page
        this.$router.push('/auth/login')
    }
    return null
}

class UserService {
    // Plain list of roles
    roleNames = [
        'admin',
        'manager',
        'user',
        'run_report',
        'create_report'
    ]
    // List of roles, suitable for a BV option prop
    roleOptions = [
        {
            text: 'Organisation Admin',
            value: 'admin'
        },
        {
            text: 'Organisation Manager',
            value: 'manager'
        },
        {
            text: 'User',
            value: 'user'
        },
        {
            text: 'Run Reports',
            value: 'run_report'
        },
        {
            text: 'Create Reports',
            value: 'create_report'
        }

    ]

    getPublicContent() {
        return axios.get(API_URL + 'tests/all');
    }

    getUserBoard() {
        return axios.get(API_URL + 'tests/user', {headers: authHeader()});
    }

    getModeratorBoard() {
        return axios.get(API_URL + 'tests/mod', {headers: authHeader()});
    }

    getAdminBoard() {
        return axios.get(API_URL + 'tests/admin', {headers: authHeader()}).catch((error) => handleAPIError(error));
    }

    getUsers(data) {
        return axios.post(API_URL + 'user/list', data, {headers: authHeader()}).catch((error) => handleAPIError(error));
    }

    getUser(userId) {
        return axios.post(API_URL + 'user/' + userId, {}, {headers: authHeader()}).catch((error) => handleAPIError(error));
    }

    disableUser(user_id) {
        return axios.post(API_URL + `user/disable`,
            {
                user_id: user_id
            },
            {
                headers: authHeader()
            });
    }

    enableUser(user_id) {
        return axios.post(API_URL + `user/enable`,
            {
                user_id: user_id
            },
            {
                headers: authHeader()
            });
    }

    removeRole(user_id, role_name) {
        return axios.post(API_URL + `user/role/remove`,
            {
                user_id: user_id,
                role_name: role_name
            },
            {
                headers: authHeader()
            });
    }

    addRole(user_id, role_name) {
        return axios.post(API_URL + `user/role/add`,
            {
                user_id: user_id,
                role_name: role_name
            },
            {
                headers: authHeader()
            });
    }


    forceResetPassword(user_id, newState = true) {
        return axios.post(API_URL + `user/force_reset_password`,
            {
                user_id: user_id,
                password_reset_required: newState
            },
            {
                headers: authHeader()
            });
    }

    sendPasswordReset(email) {
        console.log("Sending reset email")
        return axios.post(API_URL + `user/send_reset_email`,
            {
                email: email
            },
            {
                headers: authHeader()
            });
    }

    sendUpdatedPassword(email, password, reset_token) {
        console.log("Sending updated password")
        return axios.post(API_URL + `user/update_password`,
            {
                email: email,
                password: password,
                reset_token: reset_token
            },
            {
                headers: authHeader()
            });
    }

    registerUser(display_name, email, password, organisation) {
        console.log("Sending User Registration")
        return axios.post(API_URL + `user/register`,
            {
                display_name: display_name,
                email: email,
                password: password,
                organisation: organisation
            },
            {
                headers: authHeader()
            });
    }

    editUser(userId, data) {
        console.log("Sending User Registration")
        return axios.post(API_URL + `user/edit/${userId}`,
            data,
            {
                headers: authHeader()
            });
    }

    getOrgs() {
        return axios.post(API_URL + `orgs/`,
            {},
            {
                headers: authHeader()
            })
    }
}

export default new UserService();
