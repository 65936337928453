<template>
  <div class="col-md-12">
    <b-button class="rebutton" @click="$router.back()">
      Back
    </b-button>

      <h1 class="w-100 text-center">Edit User</h1>
      <form name="form" @submit.prevent="handleSave">
        <div>
          <div class="form-group">
            <label for="name">Display Name</label>
            <input
                v-model="user.display_name"
                v-validate="'required'"
                class="form-control"
                name="name"
            />
            <div v-if="submitted && errors.has('name')" class="alert-danger">
              {{ errors.first('name') }}
            </div>
          </div>
          <div class="form-group">
            <label for="email">Email</label>
            <input
              v-model="user.email"
              v-validate="'required|email|max:50'"
              type="email"
              class="form-control"
              name="email"
            />
            <div v-if="submitted && errors.has('email')" class="alert-danger">
              {{ errors.first('email') }}
            </div>
          </div>
          <div class="form-group flex-row d-flex justify-content-around">
            <b-checkbox switch v-model="user.is_admin" v-if="isAdmin">Site Admin</b-checkbox>
            <b-checkbox switch v-model="user.password_change_required">Password Expired</b-checkbox>
            <b-checkbox switch v-model="user.active">Account Enabled</b-checkbox>
          </div>
          <div class="form-group">
            <h4>Roles</h4>
            <div class="d-flex flex-row flex-nowrap" v-for="(role, idx) in us.roleOptions" v-bind:key="role.value">
              <input type="checkbox" @change="toggleRole(roles.includes(role.value), role.value)" :id="'role_'+role.value"
                     :checked="roles.includes(role.value)" :disabled="role.value === 'admin' && !isAdmin">
              <label class="ml-1 checkbox-label" :for="'role_'+role.value">{{role.text}}</label>
            </div>
          </div>
          <div class="form-group" v-if="isAdmin">
            <label for="Organisation">Organisation</label>
            <b-form-select
              v-model="user.organisation"
              :options="orgOptions"
              type="text"
              class="form-control"
              name="organisation"
            />
            <div
              v-if="submitted && errors.has('organisation')"
              class="alert-danger"
            >
              {{ errors.first('organisation') }}
            </div>
          </div>
          <div class="form-group position-relative" v-if="isDirty">
            <div v-if="loading" class="loading-button-overlay">
              <BSpinner></BSpinner>
            </div>
            <button class="btn btn-primary btn-block">Save Changes</button>
          </div>
        </div>
      </form>

      <div
        v-if="message"
        class="alert"
        :class="successful ? 'alert-success' : 'alert-danger'"
      >
        {{ message }}
      </div>
  </div>
</template>

<script>
import User from '../models/user';
import UserService from "../services/user.service";

export default {
  name: 'EditUser',
  components: {},
  data() {
    return {
      us: UserService,
      user: new User('', '', '', '', ''),
      submitted: false,
      successful: false,
      message: '',
      confirmPassword: '',
      userId: null,
      loading: false,
      roles: [],
      oldRoles: null,
      rolesDirty: false,
      oldPasswordExpiry: null,
      oldActive: null,
      oldAdmin: null,
      orgOptions: null,
      oldOrg: null
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    isAdmin() {
      console.log(this.$store.state.auth.user)
      return this.$store.state.auth.user.is_admin
    },
    isDirty() {
      console.log(Object.keys(this.$validator.fields).some(x => x.dirty))
      return this.formsDirty || this.rolesDirty || this.passwordExpiryDirty || this.activeDirty || this.adminDirty || this.orgDirty
    },
    formsDirty() {
      return Object.values(this.$validator.flags).some(x => x.dirty)
    },
    passwordExpiryDirty() {
      return this.user.password_change_required !== this.oldPasswordExpiry
    },
    activeDirty() {
      return this.user.active !== this.oldActive
    },
    adminDirty() {
      return this.user.is_admin !== this.oldAdmin
    },
    orgDirty() {
      return this.oldOrg !== this.user.organisation
    }
  },
  async mounted() {
    console.log(this.$route.params)
    await this.getOrgs()
    if (Object.hasOwn(this.$route.params, 'user_id')) {
      this.userId = this.$route.params.user_id
      this.getUserData()
    }
  },
  methods: {
    async getOrgs() {
      let response = await UserService.getOrgs()
      if (response.status === 200) {
        this.orgOptions = response.data.map((org) => org.organisation_name)
      }
    },
    async getUserData() {
      let response = await UserService.getUser(this.userId)
      if (response.status === 200) {
        console.log(response)
        this.user = response.data
        this.roles = this.user.roles.map(r => r.name)
        this.oldRoles = [...this.roles]
        this.oldActive = this.user.active
        this.oldPasswordExpiry = this.user.password_change_required
        this.oldAdmin = this.user.is_admin
        this.rolesDirty = false
        this.oldOrg = this.user.organisation
      }
    },
    hasRole(roleName) {
      return this.user.roles.some(role => role.name === roleName)
    },
    async handleSave() {
      let addRoles = this.roles.filter(r => !this.oldRoles.includes(r))
      let removeRoles = this.oldRoles.filter(r => !this.roles.includes(r))
      console.log('Add Roles: ', addRoles)
      console.log('Remove Roles: ', removeRoles)
      this.message = ''
      let isValid = await this.$validator.validate()
      if (isValid) {
        let data = {}
        if (this.formsDirty || this.passwordExpiryDirty || this.activeDirty || this.adminDirty|| this.orgDirty) {
          data = {
            display_name: this.user.display_name,
            email: this.user.email,
            active: this.user.active,
            password_change_required: this.user.password_change_required,
            is_admin: this.user.is_admin,
            organisation: this.user.organisation
          }
        }
        if (addRoles.length) {
          data.add_roles = addRoles
        }
        if (removeRoles.length) {
          data.remove_roles = removeRoles
        }
        console.log('Request: ', data)
        let result = await UserService.editUser(this.userId, data)
        console.log(result)
        if (result.status === 200) {
          await this.getUserData()
          console.log(this.oldRoles)
          console.log(this.roles)
          this.$bvToast.toast(`User '${this.user.email}' changes to user saved.`, {
            title: 'User Account Updated',
            variant: 'success',
            toaster: 'b-toaster-top-center'
          })
        } else {
          this.$bvToast.toast(`Error setting password expiry for ${this.user.email}.`, {
            title: 'Server Error',
            variant: 'danger',
            toaster: 'b-toaster-top-center'
          })
        }

      } else {
        console.log()
      }
    },
    toggleRole (currentState, roleName) {
      console.log(currentState, roleName)
      this.rolesDirty = true
      if (currentState) {
        this.roles = this.roles.filter(r => r !== roleName)
      } else {
        this.roles.push(roleName)
      }
      console.log('Roles: ', this.roles)
    },
  },
};
</script>

<style scoped>
label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.loading-button-overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.1);
}
</style>
